import React, {useEffect, useRef} from 'react';
import { Link } from 'gatsby';

const Topnav = () => {

    const headerRef = useRef();

    useEffect(() => {

        let selectHeader = headerRef.current;

        // Navbar shrink function
        var navbarShrink = function () {

            const navbarCollapsible = document.body.querySelector('#mainNav');
            if (!navbarCollapsible) {
                return;
            }
            if (document.location.pathname !== "/") {
                return;
            }
            if (window.scrollY === 0) {
                navbarCollapsible.classList.remove('navbar-shrink');
                clearHilites();
            } else {
                navbarCollapsible.classList.add('navbar-shrink');
            }
    

            // my own lame scrollspy
            let position = window.scrollY + 200;
            let navbarlinks = document.querySelectorAll('a.scrollto');
            navbarlinks.forEach(navbarlink => {
                if (!navbarlink.hash) return;
                let section = document.querySelector(navbarlink.hash);
                if (!section) return;
                if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
                    activateLinkParent(navbarlink);
                    navbarlink.classList.add('active')
                } else {
                    navbarlink.classList.remove('active')
                }
            });
        
        };
        
        
        // Collapse responsive navbar when toggler is visible
        const navbarToggler = document.body.querySelector('.navbar-toggler');
        const responsiveNavItems = [].slice.call(
            document.querySelectorAll('#navbarNavDropdown .nav-link:not(.dropdown-toggle), #navbarNavDropdown .dropdown-item')
        );

        responsiveNavItems.map(function (responsiveNavItem) {

            if (window.location.pathname !== "/") {
                if (responsiveNavItem.hash.indexOf('#') === 0) {
                     // this to an absolute link (pointing to the correct section on the hompage)
                    responsiveNavItem.href = "/" + responsiveNavItem.hash;
                }
            }

            responsiveNavItem.addEventListener('click', () => {
                if (window.getComputedStyle(navbarToggler).display !== 'none') {
                    navbarToggler.click();
                }
            });
        });

        // scrollspy shiz
        const activateLinkParent = (navLink) => {
            clearHilites();
            let parentEl = navLink.parentElement.parentElement;
            if (parentEl?.tagName === "UL") {
                let prvSibling = parentEl.previousElementSibling;
                if (prvSibling?.tagName === "A" && prvSibling?.classList.contains("dropdown-toggle")) {
                    prvSibling.classList.add('active')
                }
            }	
        }

        const clearHilites = () => {
            const topLevelNavLinks = document.querySelectorAll('#mainNav ul.navbar-nav a.nav-link');
            topLevelNavLinks.forEach(tll => {
                tll.classList.remove('active');
            });
        }

        // Shrink the navbar when page is scrolled
        document.addEventListener('scroll', navbarShrink);
        navbarShrink();
        
    }, []);

    useEffect(() => {

        let selectHeader = headerRef.current;

        const scrollToHash = () => {

            if (window.location.hash) {
                // scrollspy stuff
                const navbarToggler = document.body.querySelector('.navbar-toggler');
                const scrollToEl = document.querySelector(window.location.hash);
                let offset = (selectHeader.offsetHeight)/2;
                if (window.getComputedStyle(navbarToggler).display !== 'none') {
                    offset = 20;
                }

                if (!scrollToEl) return;
                let elementPos = scrollToEl.offsetTop;
                window.scrollTo({
                  top: elementPos - offset,
                  behavior: 'smooth'
                })
            }
        }
        scrollToHash();
    });


    return (
        <nav className="navbar navbar-expand-lg navbar-light fixed-top py-3" id="mainNav" ref={headerRef}>
            <div className="container px-lg-5">
                <Link className="navbar-brand" to="/#pagetop"></Link>
                <button className="navbar-toggler navbar-toggler-right" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav mx-auto ms-auto my-2 my-lg-0">
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">About</a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a className="dropdown-item scrollto" href="#patient-journey">The Patient Journey</a></li>
                                <li><a className="dropdown-item scrollto" href="#features">Product Features</a></li>
                                <li><a className="dropdown-item scrollto" href="#eyedog-video">See Eyedog in Action</a></li>
                                <li><a className="dropdown-item scrollto" href="#whyitworks">Why It Works</a></li>
                                <li><a className="dropdown-item scrollto" href="#eyedog-advantage">The Eyedog Advantage</a></li>
                            </ul>
                        </li>
                        <li className="nav-item"><a className="nav-link scrollto" href="#team">The Team</a></li>
                        {/* <li className="nav-item"><a className="nav-link scrollto" href="#newspress">News &amp; Updates</a></li> */}
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="newsNavbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Happenings</a>
                            <ul className="dropdown-menu" aria-labelledby="newsNavbarDropdown">
                                <li><a className="dropdown-item scrollto" href="#newspress">News &amp; Updates</a></li>
                                <li><a className="dropdown-item scrollto" href="#casestudy">Case Study</a></li>
                            </ul>
                        </li>
                        <li className="nav-item"><a className="nav-link" href="#" data-bs-toggle="modal" data-bs-target="#modal_contact">Contact</a></li>
                    </ul>
                    <ul className="navbar-nav sm-icons mr-0">
                        <li className="nav-item"><a className="nav-link px-2 fs-5" href="https://twitter.com/EyedogUS" target="_blank" title="visit our Twitter"><i className="bi bi-twitter"></i></a></li>
                        <li className="nav-item"><a className="nav-link px-2 fs-5" href="https://www.linkedin.com/company/eyedog-us/about" target="_blank" title="visit our LinkedIn"><i className="bi bi-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Topnav;
