class Api {
	
	constructor() {
	}
	
	async sendMail(postBody, spinnerid) {
		return await this.postApi("POST", "sendmail", postBody, spinnerid);
	}

	async sendZohoForm(postBody, spinnerid) {
		return await this.postApi("POST", "zohoform", postBody, spinnerid);
	}

	async getPosts(project, postType, spinnerid) {
		let postBody = {
		  "project": project,
		  "postType": postType,
		  "live": 1
		}
		
		return await this.postApi("POST", "getcmsposts", postBody, spinnerid);
	}

	
	async postApi(method, path, postBody, spinnerid) {
		
		if (spinnerid) {
			// show spinner
			document.getElementById(spinnerid).style.display = 'inline-block';
		}
		
		return fetch(`${process.env.API_ROOT}${path}`, {
			method: method,
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(postBody)
		})
        .then((response) => {
          return response.json();
        })
        .then((responseData) => {
          if (spinnerid) {
            // hide spinner
            document.getElementById(spinnerid).style.display = 'none';
          }

          return responseData;
        })
        .catch((error) => console.error(error));
	}
}

export default Api;