import React, {useEffect, useRef} from "react";
import TopNav from '../components/TopNav';
import Footer from '../components/Footer';
import Contactmodal from '../components/ContactModal';
import OptOutmodal from '../components/OptOutModal';
import ScheduleDemo from '../components/ScheduleDemo';
import NewsletterSubscribe from '../components/NewsletterSubscribe';
import ZohoWidget from '../components/ZohoWidget';
import '../assets/css/style.css';

const Layout = ({ children, ...props }) => {

    useEffect(() => {
        
        const AOS = require("aos");
        AOS.init({
          once: true,
        });
    
    }, []);

    return (
        <>
        <style dangerouslySetInnerHTML={{__html: `
            main > .container {
                padding: 100px 15px 0;
            }
        `}} />
        <TopNav />
        {children}
        <Contactmodal />
        <OptOutmodal />
        <ScheduleDemo />
        <NewsletterSubscribe />
        <ZohoWidget />
        <Footer />
        </>
    )
}

export default Layout