import React, {useEffect, useRef} from 'react';
import Api from './Api';

const Contactmodal = () => {

    const contactFormRef = useRef();
    const closeContactFormRef = useRef();
    const successModalButtonRef = useRef();

    useEffect(() => {

        let contactForm = contactFormRef.current;
        let contactFormCloseBut = closeContactFormRef.current;
        let showSuccessModalBut = successModalButtonRef.current;

        async function submitContactUs() {
            const userFname = document.getElementById("txt_ctct_firstname").value;
            const userLname = document.getElementById("txt_ctct_lastname").value;
            const userEmail = document.getElementById("txt_ctct_email").value;
            const userPhone = document.getElementById("txt_ctct_phone").value;
            const userCompany = document.getElementById("txt_ctct_company").value;
            const userMessage = document.getElementById("txt_ctct_message").value;
            
            const zohoForm = {
                "postUrl": "https://forms.zohopublic.com/dmoore3/form/EyedogContactUs/formperma/2Y_G67zVPtgrQN3YP5ewp7w5pLYQTJnTEaLpJhWQOhc/htmlRecords/submit",
                "formFields": {
                    "zf_referrer_name": "",
                    "zf_redirect_url": "",
                    "zc_gad": "",
                    "SingleLine": userFname,
                    "SingleLine1": userLname,
                    "SingleLine3": userCompany,
                    "MultiLine": userMessage,
                    "PhoneNumber_countrycode": userPhone,
                    "Email": userEmail
                }
            }
            
            // this sends the email via api
            let apiObj = new Api();
            //let sendResults = await apiObj.sendMail(postBody, "spinner-sendmail");
            //console.log(JSON.stringify("sendResults:" + sendResults));
            let sendResults = await apiObj.sendZohoForm(zohoForm, "spinner-sendmail");
            switch(sendResults.message.toUpperCase()) {
              case "SUCCESS":
                contactFormCloseBut.click();
                showSuccessModalBut.click();
                break;
              default:
                // @todo - fail code block
            }

            contactForm.classList.remove("was-validated");
            contactForm.reset();
        }

		document.getElementById("but_ctct_contactSubmit").addEventListener('click',      
			(event) => {
				event.preventDefault()
				event.stopPropagation()
				const contactForm = event.target.form;
				if (contactForm.checkValidity()) {
					submitContactUs();
				}
				contactForm.classList.add('was-validated')
		});

    }, []);



    return (
        <>
            <div className="modal fade" id="modal_contact" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-start" id="exampleModalLabel">Contact Us</h5>
                        <div>
                            <a href="https://twitter.com/EyedogUS" target="_blank" title="visit our Twitter" rel="noreferrer"><i className="bi bi-twitter"></i></a>&nbsp; <a href="https://www.linkedin.com/company/eyedog-us/" className="me-3" target="_blank" title="visit our LinkedIn" rel="noreferrer"><i className="bi bi-linkedin"></i></a>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={closeContactFormRef}></button>
                        </div>
                    </div>
                    <div className="modal-body">
                    
                        <form id="form_contact" className="needs-validation" novalidate ref={contactFormRef}>

                            <p>Are you interested in learning more about Eyedog's Wayfinding Solution? We would love to provide more information or provide a demonstration! Fill out the form below and we will get back to you right away. Or, call us at <strong>267.274.2339</strong> or email at <a href="mailto:info@eyedog.us">info@eyedog.us</a>.
				            We look forward to hearing from you!</p>

                            <div className="row">
                                <div className="col-md-6 form-floating mb-2">
                                    <input type="text" className="form-control" id="txt_ctct_firstname" title="First Name" placeholder="Enter your first name..." aria-describedby="FirstName" required />
                                    <label for="txt_ctct_firstname">First Name</label>
                                    <div className="invalid-feedback">
                                        Please provide your first name.
                                    </div>
                                </div>
                                <div className="col-md-6 form-floating mb-2">
                                    <input type="text" className="form-control" id="txt_ctct_lastname" title="Last Name" placeholder="Enter your last name..." aria-describedby="LastName" required />
                                    <label for="txt_ctct_lastname">Last Name</label>
                                    <div className="invalid-feedback">
                                        Please provide your last name.
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 form-floating mb-2">
                                    <input type="email" className="form-control" id="txt_ctct_email" title="Email" placeholder="Enter your email..." aria-describedby="Email" required />
                                    <label for="txt_ctct_email">Email address</label>
                                    <div className="invalid-feedback">
                                        Please provide your email.
                                    </div>
                                </div>
                                <div className="col-md-6 form-floating mb-2">
                                    <input type="phone" className="form-control" id="txt_ctct_phone" placeholder="Enter your phone..." aria-describedby="Phone" />
                                    <label for="txt_ctct_phone">Phone Number</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col form-floating mb-2">
                                    <input type="text" className="form-control" id="txt_ctct_company" title="Company" placeholder="Company" aria-describedby="Company" required/>
                                    <label for="txt_ctct_company">Company</label>
                                    <div className="invalid-feedback">
                                    Please provide your company.
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col form-floating mb-2">
                                    <textarea className="form-control" id="txt_ctct_message" title="Message" placeholder="Enter the message..." aria-describedby="Message" required></textarea>
                                    <label for="txt_ctct_message">Message</label>
                                    <div className="invalid-feedback">
                                        Please provide your message.
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col">
                                    <button type="submit" className="btn btn-primary form-control" id="but_ctct_contactSubmit">
                                        <i className="spinner-border spinner-border-sm buttonspinner" role="status" id="spinner-sendmail"></i> Send
                                    </button>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col">
                                    <button type="button" className="btn btn-secondary form-control" data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>

                        </form>

                    </div>

                    </div>
                </div>
            </div>

            <div className="modal fade" id="modal-email-success">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Success!</h5>
                        <button type="button" className="btn invisible" data-bs-toggle="modal" data-bs-target="#modal-email-success" ref={successModalButtonRef}>Launch success modal</button>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-center">
                        <i className="bi bi-check-circle-fill text-success align-self-center" style={{fontSize:50, color:"red"}}></i>
                        <p className="text-center"><strong>Thanks for contacting us!</strong>  An Eyedog.US representative will reply to your inquiry shortly!</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
            </div>

        </>

    );
}

export default Contactmodal;
