import React, {useEffect, useRef, useState} from 'react';
import Api from './Api';

const Scheduledemo = ({ showDemoFunc }) => {

    const [scheduleOption, setScheduleOption] = useState("Within the next few days");
    
    const demoModalButtonRef = useRef();
    const closeDemoModalButtonRef = useRef();
    const demoSuccessModalButtonRef = useRef();

    useEffect(() => {
        
		document.getElementById("but_demo_contactSubmit").addEventListener('click',      
			(event) => {
				event.preventDefault()
				event.stopPropagation()
				const demoForm = event.target.form;
				if (demoForm.checkValidity()) {
					submitRequestDemo();
				}
				demoForm.classList.add('was-validated')
		});

    }, []);


    async function submitRequestDemo() {
		const userFname = document.getElementById("txt_demo_firstname").value;
		const userLname = document.getElementById("txt_demo_lastname").value;
		const userEmail = document.getElementById("txt_demo_email").value;
		const userCompany = document.getElementById("txt_demo_company").value;
        const userTitle = document.getElementById("txt_demo_title").value;
        const userIndustry = document.getElementById("txt_demo_industry").value;
        const scheduleOptionsChx = document.getElementsByName('demoScheduleOptions');
        let schedulePreference = "";
        for (let option of scheduleOptionsChx) {
            if (option.checked) {
                schedulePreference = option.value;
                break;
            }
        }

		const zohoForm = {
            "postUrl": "https://forms.zohopublic.com/dmoore3/form/EyedogDemoRequest/formperma/7dt_4rNK2Gq7K1Xr-HPHfdkHgmifq9DW7jvDSP_6bbk/htmlRecords/submit",
            "formFields": {
                "zf_referrer_name": "",
                "zf_redirect_url": "",
                "zc_gad": "",
                "SingleLine": userFname,
                "SingleLine1": userLname,
                "SingleLine3": userCompany,
                "SingleLine4": userTitle,
                "SingleLine2": userIndustry,
                "Email": userEmail,
                "Radio": schedulePreference
            }
		}
        
        // this sends the email via api
        let apiObj = new Api();
        let sendResults = await apiObj.sendZohoForm(zohoForm, "spinner-sendDemoMail");

        switch(sendResults.message.toUpperCase()) {
          case "SUCCESS":
            //var contactModal = bootstrap.Modal.getInstance(document.getElementById('modal_contact'));
            //contactModal.hide();
            closeDemoModalButtonRef.current.click();
            //var successModal = new bootstrap.Modal(document.getElementById('modal-email-success'), {keyboard: false})
            //successModal.show();
            demoSuccessModalButtonRef.current.click();
            break;
          default:
            // @todo - fail code block
        }

		var demoForm = document.getElementById('form_demo')
		demoForm.classList.remove("was-validated");
		demoForm.reset();
	}

    
    function showDemoModal() {
        demoModalButtonRef.current.click();
    }

    return (
        <>
            <div className="modal fade" id="modal_demo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title text-start">Request a Demo</h5>
                    <button type="button" class="btn invisible" data-bs-toggle="modal" data-bs-target="#modal_demo" ref={demoModalButtonRef}>launch</button>
                    <div>
                        <a href="https://twitter.com/EyedogUS" target="_blank" title="visit our Twitter"><i className="bi bi-twitter"></i></a>&nbsp; <a href="https://www.linkedin.com/company/eyedog-us/about" className="me-3" target="_blank" title="visit our LinkedIn"><i className="bi bi-linkedin"></i></a>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={closeDemoModalButtonRef}></button>
                    </div>
                    </div>
                    <div className="modal-body">
                    
                        <form id="form_demo" className="needs-validation" novalidate>

                            <p>Seeing is believing. Reach out to our wayfinding experts to see a tailored demonstration of Eyedog Wayfinding. Or, call us at <strong>267.274.2339</strong>.</p>

                            <div className="row mb-2">
                            <div className="col-6 form-floating">
                                <input type="text" className="form-control" id="txt_demo_firstname" title="First Name" placeholder="Enter your first name..." aria-describedby="FirstName" required />
                                <label for="txt_demo_firstname">First name</label>
                                <div className="invalid-feedback">
                                    Please provide your first name.
                                </div>
                            </div>
                            <div className="col-6 form-floating">
                                <input type="text" className="form-control" id="txt_demo_lastname" title="Last Name" placeholder="Enter your last name..." aria-describedby="LastName" required />
                                <label for="txt_demo_lastname">Last name</label>
                                <div className="invalid-feedback">
                                    Please provide your last name.
                                </div>
                            </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col form-floating">
                                    <input type="text" className="form-control" id="txt_demo_company" title="Company" placeholder="Enter your company..." aria-describedby="Company" required />
                                    <label for="txt_demo_company">Company name</label>
                                    <div className="invalid-feedback">
                                        Please provide your company name.
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col form-floating">
                                    <input type="text" className="form-control" id="txt_demo_title" title="Title" placeholder="Enter your title..." aria-describedby="Title" />
                                    <label for="txt_demo_title">Title</label>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col form-floating">
                                    <input type="text" className="form-control" id="txt_demo_industry" title="Industry" placeholder="Enter your industry..." aria-describedby="Industry" />
                                    <label for="txt_demo_industry">Industry type</label>
                                </div>
                            </div>
                            <div className="row mb-2">
                            <div className="col form-floating">
                                <input type="email" className="form-control" id="txt_demo_email" title="Email" placeholder="Enter your email..." aria-describedby="Email" required />
                                <label for="txt_demo_email">Email address</label>
                                <div className="invalid-feedback">
                                    Please provide your email.
                                </div>
                            </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col form-floating">
                                    <p>How soon would you like to schedule your demonstration?</p>
                                        <div className="form-check form-check-inline" onClick={() => {setScheduleOption("Right Away!");}}>
                                            <input className="form-check-input" type="radio" name="demoScheduleOptions" id="rad_demo_schedule_1" value="Right Away!" checked={scheduleOption === "Right Away!"} />
                                            <label className="form-check-label" for="rad_demo_schedule_1">Right Away!</label>
                                        </div>
                                        <div className="form-check form-check-inline" onClick={() => {setScheduleOption("Within the next few days");}}>
                                            <input className="form-check-input" type="radio" name="demoScheduleOptions" id="rad_demo_schedule_2" value="Within the next few days" checked={scheduleOption === "Within the next few days"} />
                                            <label className="form-check-label" for="rad_demo_schedule_2">Within the next few days</label>
                                        </div>
                                        <div className="form-check form-check-inline" onClick={() => {setScheduleOption("Within the next week");}}>
                                            <input className="form-check-input" type="radio" name="demoScheduleOptions" id="rad_demo_schedule_3" value="Within the next week" checked={scheduleOption === "Within the next week"} />
                                            <label className="form-check-label" for="rad_demo_schedule_3">Within the next week</label>
                                        </div>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col">
                                    <button type="submit" className="btn btn-primary form-control" id="but_demo_contactSubmit">
                                    <i className="spinner-border spinner-border-sm buttonspinner" role="status" id="spinner-sendDemoMail">
                                    </i>
                                    Send
                                    </button>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col">
                                    <button type="button" className="btn btn-secondary form-control" data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>

                        </form>

                    </div>

                </div>
                </div>
            </div>
                
                
            {/* Email Successfully Sent modal */}
            <div className="modal fade" id="modal_demo_success">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Success!</h5>
                        <button type="button" className="btn invisible" data-bs-toggle="modal" data-bs-target="#modal-demo-success" ref={demoSuccessModalButtonRef}>Launch success modal</button>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-center">
                        <i className="bi bi-check-circle-fill text-primary align-self-center" style={{fontSize:"50px"}}></i>
                        <p className="text-center">Thank you for your interest in Eyedog.US. We look forward to meeting you and sharing the power of Eyedog wayfinding technology. A representative will contact you shortly to schedule a demo.</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
            </div>

        </>
    );
}

export default Scheduledemo;
