import React, {useEffect} from "react";

// Zoho Sales IQ widget code
export default function ZohoSalesIQ(props) {

    useEffect(() => {
        window.$zoho = window.$zoho || {};
        window.$zoho.salesiq = window.$zoho.salesiq || {
            widgetcode: process.env.REACT_APP_ZOHO_SALESIQ_CODE,
            values: {},
            ready: function () {},
        };
        const d = document;
        let s;
        s = d.createElement('script');
        s.type = 'text/javascript';
        s.id = 'zsiqscript';
        s.defer = true;
        s.src = 'https://salesiq.zoho.com/widget';
        let t;
        t = d.getElementsByTagName('script')[0];
        t.parentNode.insertBefore(s, t);
    }, []);

    return (
        <>
            <div id='zsiqwidget'></div>
        </>
    );
}