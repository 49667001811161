import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

const Footer = () => {

    const qraphQLdata = useStaticQuery(graphql`
        query {
            sitePage {
                pageContext
            }
        }
    `);
    let top3Posts = [];
    const toddPressData = (qraphQLdata)? qraphQLdata.sitePage.pageContext.allPosts : [];
    if (toddPressData) {
        top3Posts = toddPressData.slice(0, 3);
    }


    return (
        <footer class="bg-dark py-5">
            <div className="container px-4 px-lg-5">
                <div className="row">
                    <div className="col-md-4">
                        <p className="small text-light"><strong>EYEDOG.US</strong></p>
                        <p className="small text-light">The most intuitive indoor and campus-based pedestrian navigation solution.</p>

                        <p className="text-light">
                            <a href="https://twitter.com/EyedogUS" target="_blank"><i className="bi bi-twitter"></i></a>&nbsp;&nbsp;
                            <a href="https://www.linkedin.com/company/eyedog-us/about" target="_blank"><i className="bi bi-linkedin"></i></a>
                        </p>
                    </div>
                    <div className="col-md-4">
                        <p className="small text-light"><strong>RECENT POSTS AND NEWS</strong></p>
                        {top3Posts.map(post => (
                            <p className="small text-light">
                                {(() => {
                                    switch(post.postType) {
                                        case "curated":
                                        case "newsletter":
                                            const linkRx = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/;
                                            const linkUrl = (post.content.match(linkRx)[2]);
                                            return <Link href={linkUrl} target="_blank" rel="noopener noreferrer">{post.title}</Link>
                                            break;
                                        default:
                                            return <Link to={`/posts/${post.id}`}>{post.title}</Link>
                                    }
                                })()}
                            </p>
                        ))}
                    </div>
                    <div className="col-md-4">
                        <p className="small text-light"><strong>CONTACT US</strong></p>
                        <p className="small text-light">
                            <a href="#" data-bs-toggle="modal" data-bs-target="#modal_contact">Contact form</a>
                            <br />
                            {/* <a href="#" data-bs-toggle="modal" data-bs-target="#modal_demo">Request a Demo</a> */}
                            <Link to="/meetnow">Schedule your Demo</Link>
                        </p>
                        <p className="small text-light">
                            <br/>P.O. Box 317
                            <br/>Jamison, PA 18929
                            <br/>(267) 274-2339
                            <br/><a href="mailto:info@eyedog.us">info@eyedog.us</a>
                        </p>
                        <p className="small text-light">
                            <strong><Link to={"/privacy-policy"}>Privacy Policy</Link></strong>
                            <br/><strong><a className="link-light me-3" href="#" data-bs-toggle="modal" data-bs-target="#modal_optout">Do Not Sell or Share My Personal Information</a></strong>
                        </p>
                    </div>
                </div>
                <hr className="text-light" />
                <p className="small text-light">Copyright &copy;{new Date().getFullYear()}, Eyedog.US, a Division of Intraprise Solutions, Inc. All rights reserved.</p>

            </div>
        </footer>
    );
}

export default Footer;
